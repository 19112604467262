import ControlledNumberInput from 'components/form/Home/ControlledNumberInput'
import SearchFilter from 'components/searchpage/SearchFilter'
import { useState } from 'react'
import { compact } from 'lodash'
import { getSearchURLQueryParameters, joinBy, pluraliseWithNumber } from '@ama-selections/ui'
import ControlledDateRangePickerModal from 'components/dates/ControlledDateRangePickerModal'
import PropertyFiltersModal, { PropertyFilterFields } from 'components/modals/home/search/properties/PropertyFiltersModal'
import { SearchModalFormProps } from 'components/modals/home/search/SearchModal'
import Button from 'components/home/common/Button'
import { getDestinationSearchPathname } from 'data/helpers/destination'
import { SearchIcon } from 'components/icons'
import { useRouter } from 'next/router'
import { DashboardSearchFormFields } from 'data/types/home/search'
import { useFormContext } from 'react-hook-form'
import { SearchType } from 'components/modals/home/search/SearchTabs'

interface PropertySearchTabProps extends SearchModalFormProps {}

const PropertySearchTab = ({
  onClose,
}: PropertySearchTabProps) => {
  const { control, watch, setValue, formState: { errors } } = useFormContext<DashboardSearchFormFields>()

  const router = useRouter()

  const [isGuestsExpanded, setIsGuestsExpanded] = useState(false)
  const [isFiltersOpen, setIsFiltersOpen] = useState(false)

  const parseGuests = (
    adults: number = 0,
    children: number = 0,
  ) => {
    return joinBy([
      pluraliseWithNumber(adults, 'Adult', { nullable: true }),
      pluraliseWithNumber(children, 'Child', { nullable: true, pluralEnding: 'ren' }),
    ])
  }

  // treat each selected category/property type as a filter
  const calculateFilterValue = (filters?: PropertyFilterFields) => {
    if (!filters) {
      return undefined
    }

    let selectedLength = compact(Object.values(filters)).length

    if (filters.searchCategories) {
      selectedLength += filters.searchCategories.length - 1
    }

    if (filters.propertyTypes) {
      selectedLength += filters.propertyTypes.length - 1
    }

    return selectedLength > 0 ? `${pluraliseWithNumber(selectedLength, 'Filter')} Selected` : undefined
  }

  return (
    <>
      <div className="flex flex-col h-full gap-10">
        {/* Dates */}
        <ControlledDateRangePickerModal
          datePickerProps={{
            control: control,
            name: 'dates',
          }}
          selectedDates={watch('dates')}
          onClear={() => setValue('dates', {
            startDate: undefined,
            endDate: undefined,
          })}
        />

        {/* Guests */}
        <SearchFilter
          open={isGuestsExpanded}
          placeholder="Who's coming?"
          onClick={() => setIsGuestsExpanded(!isGuestsExpanded)}
          label="Guests"
          value={parseGuests(watch('adults'), watch('children'))}
        >
          <ControlledNumberInput
            placeholder="0"
            label="Adults"
            sublabel="18 or older"
            control={control}
            name="adults"
            error={errors.adults}
            className={{ container: 'px-8 pt-8 pb-12' }}
          />

          <ControlledNumberInput
            name="children"
            control={control}
            placeholder="0"
            label="Children"
            sublabel="Under 18"
            error={errors.children}
            className={{ container: 'px-8 pt-8 pb-12' }}
          />
        </SearchFilter>

        {/* Filters */}
        <SearchFilter
          placeholder="No Filters Selected"
          onClick={() => setIsFiltersOpen(true)}
          label="Filters"
          value={watch('filters') ? calculateFilterValue(watch('filters')) : undefined}
        />

        <Button
          className={{ button: 'mt-auto' }}
          style="modal"
          variant="primary"
          onClick={onClose}
          href={{
            pathname: getDestinationSearchPathname(watch('destination')),
            query: getSearchURLQueryParameters({
              sort: router?.query?.sort,
              adults: watch('adults'),
              children: watch('children'),
              arrival: watch('dates')?.startDate,
              departure: watch('dates')?.endDate,
              bedrooms: watch('filters')?.bedrooms,
              bathrooms: watch('filters')?.bathrooms,
              minimumPrice: watch('filters')?.minimumPrice,
              maximumPrice: watch('filters')?.maximumPrice,
              propertyTypes: watch('filters')?.propertyTypes,
              searchCategories: watch('filters')?.searchCategories,
              tab: SearchType.PROPERTIES,
            }),
          }}
          icon={<SearchIcon />}
        >
          Search
        </Button>
      </div>

      <PropertyFiltersModal
        isOpen={isFiltersOpen}
        onClose={() => setIsFiltersOpen(false)}
        onFilter={() => setIsFiltersOpen(false)}
      />
    </>
  )
}

export default PropertySearchTab
