import { joinBy } from '@ama-selections/ui'
import { SelectedDestinationData } from 'components/popovers/DestinationSelectionPopover/_types'

export {
  getDestinationSearchURL,
  getPropertyTypeFromSlug,
  getNumericQueryParameter,
  getSearchURLQueryParameters,
} from '@ama-selections/ui'

export type {
  SearchQueryParameterInput,
  SearchQueryParameters,
} from '@ama-selections/ui'

export const getDestinationSearchPathname = (destination?: SelectedDestinationData) => {
  if (!destination || !destination?.country?.slug) {
    return '/search'
  }

  return '/' + joinBy([
    destination.country?.slug,
    destination.region?.slug,
    destination.subregion?.slug,
  ], '/')
}
