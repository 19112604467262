import classNames from 'classnames'
import Button, { ButtonProps } from 'components/home/common/Button'
import Form from 'components/form/Home'
import { FieldError, useFormContext } from 'react-hook-form'
import { CurrencyType } from 'gql/graphql'
import { getCurrencySign } from '@ama-selections/ui'
import { LoadingIcon } from 'components/icons'
import { useQuery } from 'urql'
import { graphql } from 'gql'
import { DashboardSearchFormFields } from 'data/types/home/search'

interface ControlledPropertyFiltersProps {
  onFilter: () => void
  button: {
    style: ButtonProps['style']
  }
}

export type PropertyFilterFields = {
  bedrooms?: number | null
  bathrooms?: number | null
  minimumPrice?: number | null
  maximumPrice?: number | null
  propertyTypes?: number[] | null
  searchCategories?: number[] | string[] | null
}

const ControlledPropertyFilters = ({
  onFilter,
  button,
}: ControlledPropertyFiltersProps) => {
  const { control, watch, setValue, register, formState: { errors }, reset, getValues, setError, clearErrors } = useFormContext<DashboardSearchFormFields>()

  const [{ data: propertyTypeData, fetching: propertyTypeFetching }] = useQuery({
    query: graphql(`
      query propertyTypeTagsFilters {
        propertyTypeTags(first: 100) {
          data {
            id
            value
          }
        }
      }
    `),
  })

  const [{ data: searchCategoriesData, fetching: searchCategoriesFetching }] = useQuery({
    query: graphql(`
      query searchCategoriesFilters {
        searchCategories(first: 100) {
          data {
            id
            value
            children {
              id
              value
            }
          }
        }
      }
    `),
  })

  const clearFilters = () => {
    reset({
      adults: getValues('adults'),
      children: getValues('children'),
      filters: {
        bedrooms: 0,
        bathrooms: 0,
        minimumPrice: null,
        maximumPrice: null,
        propertyTypes: null,
        searchCategories: null,
      },
    })
  }

  return (
    <>
      <div className="flex flex-col flex-1 lg:grid lg:grid-cols-2">
        <div className="flex flex-col flex-1 lg:h-max lg:border-r-2 lg:border-grey-100">
          <Divider className="mx-24 mb-8 lg:hidden" />

          <Form.ControlledNumberInput
            label="Bedrooms"
            name="filters.bedrooms"
            placeholder="0"
            control={control}
            className={{
              container: 'px-24 lg:px-40 py-10',
              header: { label: '!text-blue-1000' },
            }}
          />

          <Form.ControlledNumberInput
            label="Bathrooms"
            name="filters.bathrooms"
            placeholder="0"
            control={control}
            className={{
              container: 'px-24 lg:px-40 py-10',
              header: { label: '!text-blue-1000' },
            }}
          />

          <Divider className="mx-24 mt-8 mb-20" />

          <div className="flex flex-col px-24 lg:px-40 mb-15">
            <span className="text-15 text-blue-1000">Budget</span>
            <span className="text-grey-650 text-[13px]">Total for your stay</span>
          </div>

          <div className="flex gap-10 px-24 lg:px-40">
            <Form.Input
              {...register('filters.minimumPrice')}
              value={watch('filters.minimumPrice') || ''}
              prefix={getCurrencySign(CurrencyType.Eur)}
              variant="grey"
              label="Minimum"
              type="number"
              error={(errors?.filters as { minimumPrice?: FieldError })?.minimumPrice?.message}
              withRing={false}
              onChange={(e) => {
                const maxPrice = watch('filters.maximumPrice')
                const minPrice = parseInt(e.target.value)
                if (!maxPrice || minPrice <= maxPrice || !minPrice) {
                  clearErrors('filters.minimumPrice')
                } else {
                  setError('filters.minimumPrice', { type: 'manual', message: 'Minimum price cannot be higher than maximum price' })
                }
                setValue('filters.minimumPrice', minPrice)
              }}
            />

            <Form.Input
              {...register('filters.maximumPrice')}
              value={watch('filters.maximumPrice') || ''}
              prefix={getCurrencySign(CurrencyType.Eur)}
              variant="grey"
              error={(errors?.filters as { maximumPrice?: FieldError })?.maximumPrice?.message}
              label="Maximum"
              type="number"
              withRing={false}
              onChange={(e) => {
                const minPrice = watch('filters.minimumPrice')
                const maxPrice = parseInt(e.target.value)
                if (!minPrice || maxPrice >= minPrice || !maxPrice) {
                  clearErrors('filters.maximumPrice')
                } else {
                  setError('filters.maximumPrice', { type: 'manual', message: 'Maximum price cannot be lower than minimum price' })
                }
                setValue('filters.maximumPrice', maxPrice)
              }}
            />
          </div>

          <Divider className="mx-24 my-20" />

          <Form.ControlledPillSelect
            className={{
              container: 'px-24 lg:px-40',
              title: 'text-blue-1000',
            }}
            control={control}
            name="filters.propertyTypes"
            title="Property Type"
            data={propertyTypeData?.propertyTypeTags.data ?? []}
            fetching={propertyTypeFetching}
          />

          <Divider className="mx-24 my-20 lg:hidden" />
        </div>

        <div className="w-full overflow-y-auto">
          {searchCategoriesFetching
            ? <div className="flex flex-col items-center justify-center">
              <LoadingIcon />
            </div>
            : <div className="flex flex-col w-full gap-15">
              <Form.ControlledCheckboxGroup
                className={{
                  container: 'px-24 lg:px-40 pb-20 lg:pb-0',
                  options: { title: '!text-blue-1000' },
                }}
                control={control}
                disabled={false}
                error={(errors?.filters as { searchCategories?: FieldError })?.searchCategories}
                name="filters.searchCategories"
                options={searchCategoriesData?.searchCategories.data?.filter((tag) => tag.children.length > 0).map((tag) => ({
                  title: tag.value,
                  items: tag.children.map((child) => ({
                    value: child.id,
                    label: child.value,
                  })),
                })) ?? []}
              />
            </div>
          }
        </div>
      </div>

      <div className={classNames(
        'sticky bottom-0 z-10 flex flex-col justify-between w-full px-24 bg-white pt-10 pb-10 gap-y-10 lg:p-40 lg:flex-row-reverse',
        {
          'pb-safe-or-10': button.style === 'default',
        },
      )}>
        <Button
          style={button.style}
          variant="primary"
          type="button"
          onClick={() => {
            if (!errors?.filters) {
              onFilter()
            }
          }}
          block={button.style === 'default'}
        >
          Apply Filters
        </Button>

        <Button
          style={button.style}
          variant={button.style === 'default' ? 'underline' : 'grey'}
          type="button"
          onClick={clearFilters}
          block={button.style === 'default'}
        >
          Clear selection
        </Button>
      </div>
    </>
  )
}

const Divider = ({ className }: { className?: string }) => {
  return <hr className={classNames('border-grey-200 opacity-40', className)} />
}

export default ControlledPropertyFilters
